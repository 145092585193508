import React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import { SEO } from '../components'
import { Title } from '../styled'
import { PolicyContainer } from '../styled/legal'

const ProductPage = ({ path }: any) => {
  const { t } = useTranslation()
  return (
    <>
      <SEO title={t('disclaimer')} path={path} />
      <PolicyContainer>
        <Title>{t('disclaimer')}</Title>
        <div className="legal">
          <h2 className="p1">
            <b>BYM GENERATION</b>
          </h2>
          <p className="p3">
            Conformément aux dispositions des Articles 6-III et 19 de la Loi
            n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
            numérique, dite L.C.E.N., il est porté à la connaissance des
            utilisateurs et visiteurs du site BYM GENERATION les présentes
            mentions légales.
          </p>
          <p className="p3">
            Le site BYM GENERATION est accessible à l’adresse suivante :
            www.bymgeneration.com (ci-après « le Site »). L’accès et
            l’utilisation du Site sont soumis aux présentes « Mentions légales »
            détaillées ci-après ainsi qu’aux lois et/ou règlements applicables.
          </p>
          <p className="p3">
            La connexion, l’utilisation et l’accès à ce Site impliquent
            l’acceptation intégrale et sans réserve de l’internaute de toutes
            les dispositions des présentes Mentions Légales.
          </p>
          <h2 className="p3">
            <b>ARTICLE 1 – INFORMATIONS LÉGALES</b>
          </h2>
          <p className="p3">
            En vertu de l’Article 6 de la Loi n° 2004-575 du 21 juin 2004 pour
            la confiance dans l’économie numérique, il est précisé dans cet
            article l’identité des différents intervenants dans le cadre de sa
            réalisation et de son suivi.
          </p>
          <p className="p3">
            Le site www.bymgeneration.com est édité par: tracy.ngot@gmail.com{' '}
          </p>
          <h3 className="p3">
            <b>BYM GENERATION</b>
          </h3>
          <p className="p3">
            Ayant son siège social à l’adresse suivante : 5 rue de Valmy 89000
            AUXERRE, et immatriculée au registre du et des sociétés d’Auxerre
            sous le numéro <b>829 809 755</b> SIRET <b>829809755 00012</b>
          </p>
          <ul>
            <li>
              Numéro de TVA Intracommunautaire : <b>FR 34 829809755</b>
            </li>
            <li>Téléphone : + 33 7 81 45 77 96</li>
            <li>
              <span className="s1">Adresse e-mail: bonjour@bymparis.com</span>
            </li>
          </ul>
          <p className="p3">Ci-après l’Editeur</p>
          <p className="p3">
            La Directrice de publication est: <b>Ursula Rothe NGOT BYMBOU</b>
          </p>
          <p className="p3">Adresse e-mail de contact: bonjour@bymparis.com</p>
          <p className="p3">
            Le site Bymgeneration est hébergé par: <strong>NETLIFY</strong>
          </p>
          <h2 className="p3">
            <b>ARTICLE 2 – ACCESSIBILITÉ</b>
          </h2>
          <p className="p3">
            Le Site est par principe accessible aux utilisateurs 24/24h et 7/7j,
            sauf interruption, programmée ou non, pour des besoins de
            maintenance ou en cas de force majeure.
          </p>
          <p className="p3">
            En cas d’impossibilité d’accès au Site, celui-ci s’engage à faire
            son maximum afin d’en rétablir l’accès. Le Site ne saurait être tenu
            pour responsable de tout dommage, quelle qu’en soit la nature,
            résultant de son indisponibilité.
          </p>
          <h2 className="p3">
            <b>
              ARTICLE 3 – COLLECTE DE DONNÉES ET LOI INFORMATIQUE ET LIBERTÉS
            </b>
          </h2>
          <p className="p3">
            Le site{' '}
            <a href="http://www.bymgeneration.com/">
              <span className="s2">www.bymgeneration.com</span>
            </a>{' '}
            est déclaré à la Commission Nationale Informatique et Libertés
            (CNIL) sous le n° 2090957.
          </p>
          <p className="p3">
            En outre, il est conforme aux dispositions de la Loi 78-17 du 6
            janvier 1978 relative à l’informatique, aux fichiers et aux
            libertés. En vertu de celle-ci l’Utilisateur bénéficie notamment
            d’un droit d’opposition (art.32 et 38), d’accès (art 38 et 39) et de
            rectification (art 40) des données le concernant.
          </p>
          <p className="p3">
            Pour faire usage de celui-ci, l’Utilisateur doit s’adresser à
            l’Editeur en le contactant à l’adresse suivante :{' '}
            <span>bonjour@bymparis.com</span>, ou par courrier adressé au
            domicile de l’Editeur en précisant ses noms, prénom(s), adresse et
            adresse(s) e-mail.
          </p>
          <h2 className="p3">
            <b>ARTICLE 4 – POLITIQUE DE COOKIES</b>
          </h2>
          <p className="p3">
            Le site Bymgeneration a éventuellement recours aux techniques de «
            cookies » lui permettant de traiter des statistiques et des
            informations sur le trafic, de faciliter la navigation et
            d’améliorer le service pour le confort de l’Utilisateur.
          </p>
          <p className="p3">
            Conformément à la législation européenne, le site Bymgeneration a
            mis à jour sa politique de confidentialité en matière de cookies.
            L’Utilisateur est libre d’accepter ou de refuser les cookies de tous
            les sites internet en modifiant les paramètres de son navigateur
            internet.
          </p>
          <h2 className="p3">
            <b>ARTICLE 5 – LOI APPLICABLE ET JURIDICTION</b>
          </h2>
          <p className="p3">
            Les présentes Mentions Légales sont régies par la loi française. En
            cas de différend et à défaut d’accord amiable, le litige sera porté
            devant les tribunaux français conformément aux règles de compétence
            en vigueur.
          </p>
          <h2 className="p3">
            <b>ARTICLE 6 – CONTACT</b>
          </h2>
          <p className="p3">
            Pour tout signalement de contenus ou d’activités illicites,
            l’Utilisateur peut contacter l’Éditeur à l’adresse suivante:{' '}
            <span>bonjour@bymparis.com</span>, ou par courrier recommandé avec
            accusé de réception adressé à l’Éditeur aux coordonnées précisées
            dans les présentes mentions légales.
          </p>
        </div>
      </PolicyContainer>
    </>
  )
}

export default ProductPage
